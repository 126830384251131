






































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import CouponCard from '@/components/CouponCard.vue';
import CouponDialog from '@/components/CouponDialog.vue';

import { CouponsRepository } from '@/repositories/CouponsRepository';
import { CouponItem } from '@/models/CouponItem';
import ContactPanel from '@/components/ContactPanel.vue';

@Component({
  components: {
    'coupon-card': CouponCard,
    CouponDialog,
    ContactPanel
  }
})
export default class P0714 extends Vue {
  // 小田急くらしサポート ID=14
  readonly ALLIANCE_SERVICE_ID = 14;

  coupons: CouponItem[] = [];

  clickedCoupon: any = null;
  isCouponDialogOpen = false; // クーポンクリック時のv-dialogをコントロールする

  get repository() {
    return new CouponsRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }

  @Watch('$auth.loading', { immediate: true })
  async loadCoupon() {
    this.coupons = this.$auth.isAuthenticated
      ? await this.repository.getByAllianceServiceId(this.ALLIANCE_SERVICE_ID)
      : await this.repository.getByAllianceServiceIdWithoutCode(
          this.ALLIANCE_SERVICE_ID
        );
  }

  /**
   * ダイアログを表示する
   */
  openCouponDialog(c: any) {
    this.clickedCoupon = c;
    this.isCouponDialogOpen = true;
    this.$dataLayer.push({
      coupon_title: c.coupon_name,
      event: 'coupon-click'
    });
  }

  //新規登録
  private signup() {
    this.$auth.loginWithRedirect({
      initialDisplay: 'signup'
    });
  }

  readonly SERVICE_SLUG = 'kurashisupport';
  //タブ定数
  readonly TAB_SCENE = 'Scene';
  readonly TAB_FEATURE = 'Feature';

  //現在のタブの値
  tabStatus = this.TAB_SCENE;

  //サービスのURL
  readonly USE_SERVICE_URL = 'https://www.odakyu.jp/kurashisupport/';

  //QA表示・非表示トグル動作（DOMを直接操作している）
  toggleAnswer(e: Event) {
    let target = e.target as HTMLElement;
    let targetParent = target.parentNode as HTMLElement;
    let targetBox = targetParent.nextSibling as HTMLElement;
    if (target.getAttribute('aria-expanded') === 'true') {
      target.setAttribute('aria-expanded', 'false');
      targetBox.setAttribute('aria-hidden', 'true');
    } else {
      target.setAttribute('aria-expanded', 'true');
      targetBox.setAttribute('aria-hidden', 'false');
    }
  }

  //ご利用シーン・サービスの特徴 上部へ
  toTabNav() {
    let elem = document.getElementById('serTabNavTop') as HTMLElement;
    let top =
      elem instanceof HTMLElement === true
        ? window.pageYOffset + elem.getBoundingClientRect().top
        : 0;
    window.scrollTo({
      top: top,
      behavior: 'smooth'
    });
  }
}
